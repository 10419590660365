import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Timeline from "../components/productTimeline"
import Btn from "../components/btn"
import Hr from "../components/hr"
import Box from "../components/box"
import Slider from "react-slick"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"
import { graphql, Link } from "gatsby"
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { NormalField } from "reactstrap-form-fields"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
// import Zoom from "@material-ui/core/Zoom"
import Slide from "@material-ui/core/Slide"

import { IoMdCheckmark } from "react-icons/io"
import {
  FaAngleRight,
  FaAngleDoubleRight,
  FaExternalLinkAlt,
} from "react-icons/fa"
import { BsArrowRight } from "react-icons/bs"
import { SiFiverr } from "react-icons/si"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import AboutMe from "../components/joesDescrip"

export const query = graphql`
  {
    allFile(
      limit: 4
      filter: { name: { regex: "/thumb/" } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 95, width: 174, layout: CONSTRAINED)
        }
      }
    }
  }
`

const primary = "#26a27b"
const useStyles = makeStyles((theme) => ({
  outerCtr: {
    width: "100vw",
    // overflowY: "hidden",
  },
  firstPage: {
    // height: "100vh",
  },
  backgroundCtr: {
    height: "89vh",
    width: "100vw",
    // background: `linear-gradient(134deg, #C6E2EF 0%, #C6E2EF 50%, #79C5E8 100%)`,
    // clipPath: `polygon(50% 0%, 100% 0%, 100% 70%)`,
    // WebkitClipPath: `polygon(50% 0%, 100% 0%, 100% 70%)`,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      opacity: 0.5,
    },
  },
  introText: {
    color: primary,
    fontWeight: 600,
    fontSize: "2.5rem",
    [theme.breakpoints.only("xl")]: {
      paddingLeft: "2.25em",
      paddingRight: "2.25em",
      lineHeight: "1.5em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  introPriceText: {
    color: primary,
    fontWeight: 500,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  secondPage: {
    // height: "125vh",

    paddingTop: "10em",
    paddingBottom: "4em",
    [theme.breakpoints.down("md")]: {
      // height: "190vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0em",
      paddingBottom: "3em",
    },
  },
  descripHeader: {
    color: primary,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5em",
    },
  },
  slider: {
    "& .slick-prev": {
      left: "-45px",
      [theme.breakpoints.only("md")]: {
        left: "-20px",
      },
      [theme.breakpoints.only("xs")]: {
        // display: "none !important",
        zIndex: 1402,
        left: 0,
        height: 40,
        width: 40,
      },
    },
    "& .slick-next": {
      [theme.breakpoints.only("xs")]: {
        height: 40,
        width: 40,
      },
    },
    "& button": {
      // backgroundColor: "#fff",
      zIndex: 1,
      [theme.breakpoints.only("md")]: {
        right: "0px",
      },
      [theme.breakpoints.only("xs")]: {
        backgroundColor: "transparent",
        right: 0,
        // display: "none !important",
      },
      "&::before": {
        color: "#777",

        fontSize: "2.5rem",
      },
    },
  },
  slickDots: {
    position: "absolute",
    bottom: "-25px",
    display: "block",
    width: "100%",
    padding: 0,
    margin: 0,
    listStyle: "none",
    textAlign: "center",
    "& li": {
      position: "relative",
      display: "inline-block",
      width: 20,
      height: 20,
      margin: "0 5px",
      padding: 0,
      cursor: "pointer",
      "& .slick-active": {
        "& button": {
          "&::before": {
            opacity: 0.75,
            color: "black",
          },
        },
      },
      "& button": {
        fontSize: 0,
        lineHeight: 0,
        display: "block",
        width: 20,
        height: 20,
        padding: 5,
        cursor: "pointer",
        color: "transparent",
        border: 0,
        outline: "none",
        background: "transparent",
        "&::before": {
          fontFamily: "slick",
          fontSize: "6px",
          lineHeight: "20px",
          position: "absolute",
          top: 0,
          left: 0,
          width: 20,
          height: 20,
          content: "•",
          textAlign: "center",
          opacity: 0.25,
          color: "black",
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },
        "&:hover": {
          outline: "none",
          "&::before": {
            opacity: 1,
          },
        },
        "&:focus": {
          outline: "none",
          "&::before": {
            opacity: 1,
          },
        },
      },
    },
  },
  slickThumb: {
    bottom: "-8.1025em",
    "& li": {
      width: "23%",
      height: "8.125em",
      "& img": {
        [theme.breakpoints.only("xs")]: {
          display: "none !important",
        },
        width: "100%",
        height: "100%",
        // maxHeight: "130px",
        filter: "grayscale(100%)",
        borderRadius: 10,
      },
    },
    "& li.slick-active": {
      "& img": {
        filter: "grayscale(0%)",
      },
    },
  },
  paperCrd: {
    marginTop: "2em",
    height: "35.8125em",
    width: "30.0625em",
    border: "1px solid #9A9A9A",
    [theme.breakpoints.down("lg")]: {
      width: "25.625em",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "60vw",
      height: "30em",
      marginTop: "5em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
      marginTop: "1.75em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-5em",
      width: "90vw",
      height: "30.8125em",
    },
  },
  ico: {
    marginLeft: "4.5em",
    marginRight: "-.5em",
    color: "darkgreen",
    verticalAlign: "baseline",
  },
  continueBtn: {
    backgroundColor: primary,
    border: "1px solid #9a9a9a",
    "&:hover": {
      backgroundColor: "#17614a",
    },
  },
  filledBtn: {
    color: "white",
    backgroundColor: primary,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: primary,
      opacity: 0.9,
      color: "white",
    },
  },
  outlinedBtn: {
    borderRadius: 0,
    backgroundColor: "white",
    border: `1px solid ${primary}`,
    color: primary,
    "&:hover": {
      backgroundColor: primary,
      color: "white",
    },
  },
  continueText: {
    fontWeight: 500,
    color: "white",
    textTransform: "capitalize",
  },
  thirdPage: {
    // height: "125vh",
    paddingTop: "5em",
    [theme.breakpoints.up("xl")]: {
      paddingTop: "10em",
    },
    [theme.breakpoints.only("lg")]: {
      paddingTop: "7em",
    },
    [theme.breakpoints.down("md")]: {
      // height: "180vh",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  aboutHeaderH: {
    color: primary,
    fontWeight: 500,
  },
  aboutDescripP: {
    fontSize: "1.25rem",
    lineHeight: "2.25rem",
  },
  listIco: {
    marginRight: "1em",
    color: primary,
    opacity: 0.5,
  },
  liTarget: {
    "& li": {
      paddingTop: ".5em",
    },
    marginLeft: "-2em",
  },
  mainLiItem: {
    fontWeight: 500,
    color: primary,
  },
  projectBtn: {
    backgroundColor: `${primary}`,
    height: "25vh",
    width: "80vh",
    transition: "0.3s",
    cursor: "pointer",
    color: "#fff",
    "&:hover": {
      backgroundColor: `#fff`,
      "& h3": {
        color: primary,
      },
      "& h6": {
        color: primary,
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30vh",
      width: "90vw",
    },
  },
}))

export default function Jsblog({ data }) {
  const {
    allFile: { nodes: QImage },
  } = data
  // const [mounted, setMounted] = React.useState(false)
  const [isMounted, setIsMounted] = React.useState(false)
  const classes = useStyles()
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up("xl"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXS = useMediaQuery(theme.breakpoints.only("xs"))
  const matchesMD = useMediaQuery(theme.breakpoints.only("md"))
  const matchesLG = useMediaQuery(theme.breakpoints.only("lg"))

  useEffect(() => {
    // setMounted(false)
    setIsMounted(true)
    // setTimeout(() => {
    //   setMounted(true)
    // }, 500)
  }, [])
  const settings = {
    customPaging: function (i) {
      const slideImg = getImage(QImage[i])

      return (
        <a href="#!">
          {/* <img src={`image${i + 1}.jpg`} /> */}
          <GatsbyImage image={slideImg} alt={"slide img" + i} />
        </a>
      )
    },
    dots: true,
    dotsClass: `${classes.slickThumb} ${classes.slickDots}`,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  let LineItem = ({ line, item }) => {
    return (
      <Grid item xs={12} style={{ paddingTop: ".5em" }}>
        <Grid container direction="row">
          <Grid item xs={8}>
            <Typography variant="body1">{line}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" align="right">
              {item}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  let ContactForm = () => (
    <form name="Starter Blog" method="POST" netlify data-netlify="true">
      <input type="hidden" name="form-name" value="Starter Blog" />
      <NormalField name="Full Name" />
      <NormalField name="Email" />
      <NormalField name="Phone Number" />
      <NormalField name="Comments" type="textarea" />
      <Btn type="submit">Submit</Btn>
    </form>
  )

  const linkToShopify = (e) => {
    e.preventDefault()
    window.open("https://snappywebdesign.square.site/", "_blank")
  }

  return (
    <>
      <Layout>
        <Seo
          title="Start a business website for $70"
          description="Shop websites for sale that make money at Snappy Web Design, the established Web Design Company. Purchase through secured sources like our Shopify store or Fiverr"
        />
        <Grid container direction="column" className={classes.outerCtr}>
          <Grid item>
            <Grid container direction="row" className={classes.firstPage}>
              <div className={classes.backgroundCtr} />

              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={12} md={6}>
                    <StaticImage
                      src="../assets/images/smartmockups_kpeelcia.jpg"
                      alt="honeybee website theme"
                      height={700}
                      quality={100}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Slide
                      direction="right"
                      in={isMounted}
                      mountOnEnter
                      unmountOnExit
                    >
                      <Grid container direction="column">
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          style={{
                            paddingLeft: "4em",
                            paddingRight: "4em",
                            paddingTop: "1em",
                          }}
                        >
                          <Typography
                            variant="h1"
                            className={classes.introText}
                            gutterBottom={true}
                            align="center"
                          >
                            {/* <span style={{ color: "#37A8DC" }}>Finally, </span>{" "}
                            a <span style={{ color: "#37A8DC" }}>website</span>{" "}
                            for sale that makes{" "}
                            <span style={{ color: "#37A8DC" }}>money</span>. */}
                            Finally, a website for sale that makes money.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Typography
                            variant="h6"
                            className={classes.introPriceText}
                            align="center"
                          >
                            From $70
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            justify="center"
                            spacing={5}
                            style={{ paddingTop: "3em" }}
                          >
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                className={classes.filledBtn}
                                component={OutboundLink}
                                href="https://snappytheme.shop/"
                              >
                                <FaExternalLinkAlt
                                  style={{ marginRight: ".5em" }}
                                />{" "}
                                View Live Demo
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                className={classes.outlinedBtn}
                                component={Link}
                                to="/contact"
                              >
                                Ask me a question
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                className={classes.filledBtn}
                                component={OutboundLink}
                                href="https://www.fiverr.com/share/eN5meD"
                              >
                                <SiFiverr style={{ marginRight: ".5em" }} />{" "}
                                Shop on Fiverr
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Slide>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.secondPage}>
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    style={{
                      paddingLeft: mdDown
                        ? undefined
                        : lgUp
                        ? "12em"
                        : // : "10.2375em",
                          "4em",
                      paddingBottom: smDown ? "8em" : undefined,
                    }}
                  >
                    <Grid container justify={smDown ? "center" : undefined}>
                      <Grid item xs={11} sm={7} md={7} lg={8} xl={8}>
                        <Typography
                          variant={mdDown ? "h6" : "h5"}
                          align={smDown ? "center" : undefined}
                          className={classes.descripHeader}
                          style={{
                            marginLeft: matchesMD ? "2.5em" : undefined,
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>You will get</span>{" "}
                          a beautiful single page site with free hosting
                        </Typography>
                      </Grid>
                    </Grid>

                    <Slider
                      {...settings}
                      className={classes.slider}
                      style={{
                        width: matchesXS
                          ? "100vw"
                          : smDown
                          ? "40em"
                          : mdDown
                          ? // ? "60vw"
                            "53vw"
                          : "43em",
                        paddingTop: "2.3125em",
                        marginRight: smDown ? "auto" : undefined,
                        marginLeft: matchesMD
                          ? "3em"
                          : smDown
                          ? "auto"
                          : undefined,
                        // marginRight: mdDown ? "auto" : undefined,
                        // marginLeft: mdDown ? "auto" : undefined,
                      }}
                    >
                      <div>
                        <StaticImage
                          src="../assets/images/thumb1.jpg"
                          height={560}
                          aspectRatio={4 / 3}
                          layout="constrained"
                          placeholder="blurred"
                          alt="dark mode slide"
                          quality={100}
                          imgStyle={{
                            objectFit: "contain",
                            borderRadius: 10,
                          }}
                          transformOptions={{ cropFocus: "center" }}
                        />
                      </div>
                      <div>
                        <StaticImage
                          src="../assets/images/thumb2.png"
                          height={560}
                          width={746}
                          layout="constrained"
                          placeholder="blurred"
                          alt="light mode slide"
                          quality={100}
                          imgStyle={{
                            objectFit: "contain",
                            borderRadius: 10,
                          }}
                          transformOptions={{ cropFocus: "center" }}
                        />
                      </div>
                      <div>
                        <StaticImage
                          src="../assets/images/thumb3.png"
                          height={560}
                          aspectRatio={4 / 3}
                          layout="constrained"
                          placeholder="blurred"
                          alt="example slide"
                          quality={100}
                          imgStyle={{
                            objectFit: "contain",
                            borderRadius: 10,
                          }}
                          transformOptions={{ cropFocus: "bottom" }}
                        />
                      </div>
                      <div>
                        <StaticImage
                          src="../assets/images/thumb4.png"
                          height={560}
                          aspectRatio={4 / 3}
                          layout="constrained"
                          placeholder="blurred"
                          alt="video slide"
                          quality={100}
                          imgStyle={{
                            objectFit: "contain",
                            borderRadius: 10,
                          }}
                          transformOptions={{ cropFocus: "center" }}
                        />
                      </div>
                    </Slider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    lg={5}
                    style={{
                      marginLeft: matchesXS ? 0 : mdDown ? "auto" : undefined,
                      marginRight: matchesXS ? 0 : mdDown ? "auto" : undefined,
                    }}
                  >
                    <Grid container direction="row">
                      <Paper className={classes.paperCrd} elevation={3}>
                        <Grid
                          container
                          direction="column"
                          style={{ padding: "2.25em" }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: 500 }}
                            >
                              What's included
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: "1em" }}>
                            <Typography variant="subtitle1">
                              (standard configuration)
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              paddingTop: matchesMD
                                ? "1em"
                                : smDown
                                ? "1.25em"
                                : "3.5em",
                            }}
                          >
                            <LineItem line="Delivery Time" item="3 Days" />
                            <LineItem line="Number of Revisions" item="1" />
                            <LineItem line="Number of Sections" item="6" />
                            <LineItem
                              line="Design Customization"
                              item={
                                <IoMdCheckmark
                                  size={20}
                                  className={classes.ico}
                                />
                              }
                            />
                            <LineItem
                              line="Responsive Design"
                              item={
                                <IoMdCheckmark
                                  size={20}
                                  className={classes.ico}
                                />
                              }
                            />
                            <LineItem
                              line="Contact Form"
                              item={
                                <IoMdCheckmark
                                  size={20}
                                  className={classes.ico}
                                />
                              }
                            />
                            <LineItem
                              line="Free Hosting"
                              item={
                                <IoMdCheckmark
                                  size={20}
                                  className={classes.ico}
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: "1.5em" }}>
                            <Button
                              variant="contained"
                              fullWidth
                              className={classes.continueBtn}
                              onClick={linkToShopify}
                            >
                              <Typography
                                variant="h6"
                                className={classes.continueText}
                              >
                                Continue
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.thirdPage}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  style={{
                    // paddingLeft: mdDown
                    //   ? undefined
                    //   : lgUp
                    //   ? "12em"
                    //   : "10.2375em",
                    paddingLeft: mdDown ? undefined : lgUp ? "5em" : "2em",
                    paddingRight: mdDown ? undefined : lgUp ? "5em" : "2em",
                    paddingBottom: lgUp
                      ? "7.5em"
                      : matchesLG
                      ? "10em"
                      : mdDown
                      ? "10em"
                      : undefined,
                  }}
                  justify={mdDown ? "center" : "space-around"}
                >
                  <Grid item xs={10} sm={7} md={7} lg={5}>
                    <Grid container>
                      <Typography
                        variant="h3"
                        className={classes.aboutHeaderH}
                        gutterBottom={true}
                      >
                        About
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.aboutDescripP}
                        gutterBottom={true}
                      >
                        This sleek professional website comes ready to use out
                        of the box. Get your business started with a single page
                        site that includes a contact form, product showcase,
                        analytics, and 6+ sections. We'll host your site for
                        free, forever, and give you full control.
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.aboutDescripP}
                        gutterBottom={true}
                      >
                        Did we mention this blog comes with free hosting,
                        automatic newsletter integration (100 new users / 2,000
                        email sends per month), and realtime website statistics
                        via Google Analytics?
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.aboutDescripP}
                        gutterBottom={true}
                        style={{ paddingTop: "1em" }}
                      >
                        It includes the following sections (6):
                      </Typography>

                      <Grid item xs={12}>
                        <ul
                          style={{ paddingTop: "1em", listStyleType: "none" }}
                          className={classes.liTarget}
                        >
                          <li>
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>About Us</span>
                          </li>
                          <li>
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>
                              Featured Items/Products
                            </span>
                          </li>
                          <li>
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>Our Team</span>
                          </li>
                          <li>
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>
                              {" "}
                              Featured Section Header
                            </span>
                            {/* <span> - displays all categories & posts</span> */}
                            <ul style={{ listStyleType: "none" }}>
                              <li>
                                <FaAngleDoubleRight
                                  className={classes.listIco}
                                />
                                <span className={classes.mainLiItem}>
                                  Featured Items List
                                </span>
                                {/* <span>
                                {" "}
                                - displays posts under a specific category
                              </span> */}
                              </li>
                            </ul>
                          </li>
                          <li>
                            {" "}
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>
                              Testimonials
                            </span>
                          </li>
                          <li>
                            {" "}
                            <FaAngleRight className={classes.listIco} />
                            <span className={classes.mainLiItem}>
                              Contact Form
                            </span>
                          </li>
                        </ul>
                        <OutboundLink
                          href="https://snappytheme.shop/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Typography
                            variant="body2"
                            style={{ color: `${primary}`, fontSize: "1rem" }}
                          >
                            View Live Demo <ExitToAppIcon />
                          </Typography>
                        </OutboundLink>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={5}
                    style={{
                      paddingLeft: matchesXS
                        ? "0em"
                        : smDown
                        ? "5em"
                        : mdDown
                        ? "15em"
                        : undefined,
                      paddingRight: matchesXS
                        ? ".0em"
                        : smDown
                        ? "5em"
                        : mdDown
                        ? "15em"
                        : undefined,
                      paddingTop: smDown ? "5em" : mdDown ? "5.5em" : undefined,
                    }}
                  >
                    <Typography
                      variant="h3"
                      className={classes.aboutHeaderH}
                      gutterBottom={true}
                      align="center"
                    >
                      Timeline
                    </Typography>
                    <Timeline />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justify="center"
                  style={{
                    paddingLeft: matchesXS ? "1em" : "3em",
                    paddingRight: matchesXS ? "1em" : "3em",
                    paddingBottom: "2em",
                  }}
                >
                  <Paper
                    elevation={1}
                    className={classes.projectBtn}
                    onClick={linkToShopify}
                  >
                    <Grid
                      container
                      direction="row"
                      style={{ padding: "1em", height: "100%" }}
                      justify="space-around"
                    >
                      <Grid item xs={12} style={{ height: "75%" }}>
                        <Typography
                          variant="h3"
                          style={{
                            lineHeight: "2.8rem",
                          }}
                          className={classes.projectBtnHover}
                        >
                          Browse and shop projects
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          style={{
                            fontWeight: 500,
                            marginTop: ".25em",
                          }}
                          className={classes.projectBtnHover}
                        >
                          Project Catalog <BsArrowRight size={20} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center" style={{ padding: "2em" }}>
                  <Typography
                    variant="h3"
                    style={{
                      color: `${primary}`,
                      fontWeight: 600,
                      textTransform: "uppercase",
                      borderBottom: `3px solid ${primary}`,
                    }}
                  >
                    or
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "2em" }}>
                <Grid container justify="center" style={{ padding: "3em 1em" }}>
                  <Box
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      variant="h4"
                      align="center"
                      style={{ color: `${primary}` }}
                    >
                      Get in touch directly
                    </Typography>
                    <Hr />
                    <ContactForm />
                  </Box>
                </Grid>
              </Grid>
              <AboutMe />
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </>
  )
}
