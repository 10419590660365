import React from "react"
import styled from "styled-components"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"
import { FaLinkedin, FaGithub } from "react-icons/fa"
import Link from "../components/link"

import headshot from "../assets/images/headshot.jpg"
const primary = "#26a27b"

const Hr = styled.hr`
  width: 100%;
  border-radius: 0.5rem;
  border-width: 0.1rem;
  background: ${(props) => props.theme.primary};
`

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    paddingLeft: "5em",
    paddingRight: "5em",
    paddingBottom: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1.5em",
      paddingRight: "1.5em",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
  },
  image: {
    width: 128,
    height: 150,
  },
  green: {
    // color: "#e1f4ef",
    // backgroundColor: "#147452",
    width: theme.spacing(14),
    height: theme.spacing(14),
    border: "10px solid #e1f4ef",
  },
  chip: {
    marginLeft: 2.5,
    marginRight: 2.5,
    backgroundColor: `${primary}`,
    color: "white",
    borderRadius: "5px",
    height: "28px",
    marginTop: 5,
  },
}))

export default function JoesDescrip() {
  const theme = useTheme()
  const classes = useStyles()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <Grid item container justify="center" className={classes.rowContainer}>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Paper
          className={classes.paper}
          style={{ borderRadius: "6px" }}
          elevation={3}
        >
          <Grid
            container
            spacing={matchesXS ? 0 : 2}
            justify={matchesXS ? "center" : undefined}
          >
            <Grid item>
              <ButtonBase className={classes.image} disableRipple>
                <Avatar
                  src={headshot}
                  alt="Professional headshot"
                  className={classes.green}
                >
                  J
                </Avatar>
              </ButtonBase>
            </Grid>

            <Grid
              item
              xs={10}
              lg={10}
              sm
              container
              justify={matchesXS ? "center" : undefined}
            >
              <Grid
                item
                xs
                container
                direction="column"
                alignItems={matchesXS ? "center" : undefined}
                spacing={2}
              >
                <Grid item xs>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "1em", color: `${primary}` }}
                    gutterBottom
                  >
                    Joe S.
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 500, fontSize: ".95rem" }}
                    gutterBottom
                  >
                    Web Developer & Accounting Student
                  </Typography>
                  <Grid
                    item
                    container
                    direction="row"
                    className="mt-1"
                    spacing={1}
                  >
                    <Grid item>
                      <Link to="https://linkedin.com">
                        <Chip
                          variant="outlined"
                          size="small"
                          icon={
                            <FaLinkedin
                              style={{
                                width: 15,
                                height: 15,
                                verticalAlign: "baseline",
                                marginLeft: 5,
                              }}
                            />
                          }
                          label="LinkedIn"
                        />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="https://github.com/SnappyWebDesign">
                        <Chip
                          variant="outlined"
                          size="small"
                          icon={
                            <FaGithub
                              style={{
                                width: 15,
                                height: 15,
                                verticalAlign: "baseline",
                                marginLeft: 5,
                              }}
                            />
                          }
                          label="Github"
                        />
                      </Link>
                    </Grid>
                  </Grid>
                  <Hr />
                  <Grid item className="mt-3">
                    <h6>Bio</h6>
                    <p className="text-muted">joe@snappywebdesign.net</p>
                  </Grid>
                  <Grid item className="my-1">
                    <Typography variant="body1">
                      I first began learning how to code in fifth grade as a
                      hobby interest. I returned to coding in 2019 with the goal
                      of developing a skillset that would aid me in designing &
                      creating solutions to unique problems that could arise in
                      my career, or minor daily inconveniences, like tracking
                      expenses. After quickly realizing that applications
                      without elegant user interfaces are near impossible for
                      external users to navigate, I set out to re-learn web
                      design.
                    </Typography>
                    <Typography variant="body1" className="mt-2 mb-2">
                      I honed my skills by creating websites for friends and
                      various passion projects. Now, I'm ready to help local
                      business owners, organizations, and creative individuals
                      deliver seamless digital experiences that users are
                      growing to expect in the new digital age.
                    </Typography>
                  </Grid>
                  <Grid item className="py-2">
                    <Chip label="HTML" className={classes.chip} />
                    <Chip label="CSS" className={classes.chip} />
                    <Chip label="Javascript" className={classes.chip} />
                    <Chip label="React.js" className={classes.chip} />
                    <Chip label="Node.js" className={classes.chip} />
                    <Chip label="Gatsby.js" className={classes.chip} />
                    <Chip label="Next.js" className={classes.chip} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
